import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    
  {
        path: '/',
        name: 'login',
        component: () => import('../components/Login')
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../components/Menu')
},
  {
    path: '/trabajadores',
    name: 'trabajadores',
    component: () => import('../components/Trabajadores')
  },
  {
    path: '/contratos',
    name: 'contratos',
    component: () => import('../components/Contratos')
  },

  {
    path: '/trabajos',
    name: 'trabajos',
    component: () => import('../components/Trabajos')
  },

  {
    path: '/pagos',
    name: 'pagos',
    component: () => import('../components/Pagos')
  },

  {
    path: '/',
    name: 'menu',
    component: () => import('../components/Menu')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../components/List')
  },
  
  {
    path: '/firma',
    name: 'firma',
    component: () => import('../components/firma')
  },

  {
    path: '/transportistas',
    name: 'transportistas',
    component: () => import('../components/Transportistas')
  },
  {
    path: '/predios',
    name: 'predios',
    component: () => import('../components/Predios')
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('../components/Usuarios')
  },
  {
    path: '/reportes',
    name: 'reportes',
    component: () => import('../components/Reportes')
  },
  {
    path: '/supervisor',
    name: 'supervisor',
    component: () => import('../components/Menu2')
  },
  {
    path: '/editartrabajos',
    name: 'editartrabajos',
    component: () => import('../components/EditarTrabajos')
  },
  {
    path: '/modificartrabajos',
    name: 'modificartrabajos',
    component: () => import('../components/EditarTrabajosSupervisor')
  },

  {
    path: '/trabajadoressupervisor',
    name: 'trabajadoressupervisor',
    component: () => import('../components/TrabajadoresSupervisor')
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router